import React, { useState } from 'react';
import './HowToPlay.css';
import { NavLink } from 'react-router-dom';

interface TabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const Tab: React.FC<TabProps> = ({ label, isActive, onClick }) => (
  <div
    className={`tab ${isActive ? 'active' : ''}`}
    onClick={onClick}
    style={{
      padding: '10px 20px',
      cursor: 'pointer',
      backgroundColor: isActive ? '#3856B0' : '#2a4086',
      borderRadius: '10px 10px 0 0',
      margin: '0 5px',
    }}
  >
    {label}
  </div>
);

interface StepItem {
  text: string;
  image?: string;
  imageSize?: 'small' | 'medium' | 'large';
}

interface StepItem {
  text: string;
  image?: string;
  imageSize?: 'small' | 'medium' | 'large';
}

class HowToPlay extends React.Component {
  state = {
    activeTab: 'living-room'
  };

  getImageMaxWidth(size: 'small' | 'medium' | 'large' = 'medium'): number {
    switch (size) {
      case 'small': return 300;
      case 'large': return 800;
      case 'medium':
      default: return 500;
    }
  }

  renderStepBox(step: StepItem, index: number) {
    return (
      <div key={index} style={{
        backgroundColor: '#2a4086',
        borderRadius: '10px',
        padding: '20px',
        margin: '15px 0',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '20px',
        position: 'relative',
      }}>
        <div style={{
          backgroundColor: '#FFA500',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          fontSize: '1.2em',
          fontWeight: 'bold',
        }}>
          {index + 1}
        </div>
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}>
          <div style={{ fontSize: '1.1em' }}>{step.text}</div>
          {step.image && (
            <img 
              src={step.image} 
              alt={`Step ${index + 1}`} 
              style={{
                width: '100%',
                maxWidth: `${this.getImageMaxWidth(step.imageSize)}px`,
                borderRadius: '5px',
                marginTop: '10px',
              }}
            />
          )}
        </div>
      </div>
    );
  }

  renderTabContent() {
    const livingRoomSteps: StepItem[] = [
      {
        text: "Download and install the game from Steam and hook it up to your TV"
      },
      {
        text: "Launch the game and select the settings menu in top left",
        image: "/images/lobby-screen.png",
        imageSize: 'large'
      },
      {
        text: "Create a lobby name and press 'Save & Re-Connect' , then close the settings menu",
        image: "/images/enter-channel.png",
        imageSize: 'medium'
      },
      {
        text: "Other players scan the QR code displayed on screen",
        image: "/images/qr-code.png",
        imageSize: 'small'
      },
      {
        text: "Players enter commands by pressing the buttons",
        image: "/images/controls.png",
        imageSize: 'large'
      }
    ];

    const discordSteps: StepItem[] = [
      {
        text: "Download and install the game from Steam"
      },
      {
        text: "Launch the game and select the settings menu in top left",
        image: "/images/lobby-screen.png",
        imageSize: 'medium'
      },
      {
        text: "Create a lobby name and press 'Save & Re-Connect' , then close the settings menu",
        image: "/images/enter-channel.png",
        imageSize: 'large'
      },
      {
        text: "Share your game screen with the other players on Discord",
      },
      {
        text: "Other players scan the QR code displayed on screen",
        image: "/images/qr-code.png",
        imageSize: 'small'
      },
      {
        text: "Players enter commands by pressing the buttons",
        image: "/images/controls.png",
        imageSize: 'large'
      }
    ];

    const streamSteps: StepItem[] = [
        {
            text: "Download and install the game from Steam"
          },
          {
            text: "Launch the game and select the settings menu in top left",
            image: "/images/lobby-screen.png",
            imageSize: 'medium'
          },
          {
            text: "Create a lobby name and press 'Save & Re-Connect' , then close the settings menu",
            image: "/images/enter-channel.png",
            imageSize: 'large'
          },
          {
            text: "Go Live on your Twitch channel",
          },
          {
            text: "Other players scan the QR code displayed on screen",
            image: "/images/qr-code.png",
            imageSize: 'small'
          },
          {
            text: "Players enter commands by pressing the buttons, or by typing commands in Twitch chat (example: !boost)",
            image: "/images/controls.png",
            imageSize: 'large'
          }
    ];

    switch (this.state.activeTab) {
      case 'living-room':
        return (
          <div>
            <h2 style={{ 
              marginBottom: '20px',
              color: '#FFFFFF',
              textAlign: 'center'
            }}>Living Room Mode</h2>
            {livingRoomSteps.map((step, index) => 
              this.renderStepBox(step, index)
            )}
          </div>
        );
      case 'discord':
        return (
          <div>
            <h2 style={{ 
              marginBottom: '20px',
              color: '#FFFFFF',
              textAlign: 'center'
            }}>Discord Mode</h2>
            {discordSteps.map((step, index) => 
              this.renderStepBox(step, index)
            )}
          </div>
        );
      case 'stream':
        return (
          <div>
            <h2 style={{ 
              marginBottom: '20px',
              color: '#FFFFFF',
              textAlign: 'center'
            }}>Stream Mode</h2>
            {streamSteps.map((step, index) => 
              this.renderStepBox(step, index)
            )}
          </div>
        );
      default:
        return null;
    }
  }

  renderVideoPanel(title: string, videoSrc: string, description: string) {
    return (
      <div key={title} style={{
        backgroundColor: '#2a4086',
        padding: '20px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center',
        textAlign: 'center'
      }}>
        <h3 style={{ margin: '0 0 10px 0' }}>{title}</h3>
        <video 
          autoPlay 
          loop 
          muted 
          playsInline
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
        >
          <source src={`/videos/${videoSrc}`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <p style={{ margin: '10px 0 0 0' }}>{description}</p>
      </div>
    );
  }

  render() {
    return (
      <div className="how-to-play-container" style={{ padding: '20px' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '30px' }}>How To Play</h1>
        
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center',
          marginBottom: '20px'
        }}>
          <Tab
            label="Living Room"
            isActive={this.state.activeTab === 'living-room'}
            onClick={() => this.setState({ activeTab: 'living-room' })}
          />
          <Tab
            label="Discord"
            isActive={this.state.activeTab === 'discord'}
            onClick={() => this.setState({ activeTab: 'discord' })}
          />
          <Tab
            label="Stream"
            isActive={this.state.activeTab === 'stream'}
            onClick={() => this.setState({ activeTab: 'stream' })}
          />
        </div>

        <div style={{
          backgroundColor: '#3856B0',
          padding: '20px',
          borderRadius: '10px',
        }}>
          {this.renderTabContent()}
        </div>

        <h2 style={{ textAlign: 'center', margin: '40px 0 20px' }}>Game Controls</h2>
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: '20px',
          padding: '20px',
          justifyContent: 'center',
          maxWidth: '1200px',
          margin: '0 auto',
        }}>
          {this.renderVideoPanel('BOOST', 'BoostHomePage.mp4', 'A quick surge of speed, but can be dangerous!')}
          {this.renderVideoPanel('RAM', 'RamHomeWeb.mp4', 'Attack your opponents and knock them off the road!')}
          {this.renderVideoPanel('SHIELD', 'Shieldhomeweb.mp4', 'Protect yourself from damage for a few seconds')}
          {this.renderVideoPanel('BRAKE', 'BrakeTest.mp4', 'Slam on the brakes to attack an opponent behind you')}
        </div>
      </div>
    );
  }
}

export default HowToPlay;
