import React from 'react';
import {NavLink, Navigate, Outlet, useNavigate} from 'react-router-dom';
import { Account } from './services/Account';
import { BsArchiveFill, BsDiscord, BsBroadcast, BsBookFill, BsCarFrontFill, BsEnvelope, BsFacebook, BsFillHouseFill, BsFillPlayCircleFill, BsFillTrophyFill, BsFilterCircleFill, BsGoogle, BsPersonCircle, BsTools, BsTwitch, BsTwitter, BsShieldLock, BsTiktok } from 'react-icons/bs';
import './MainPage.css';
import { Network } from './services/Network';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { PlayFab } from './services/PlayFab';
import { useLocation } from 'react-router-dom';

const menu = [
  {label: 'Home', link: '/', icon: <BsFillHouseFill style={{transform: 'translateY(2px)'}} />},
  {label: 'Play', link: '/play', icon: <BsFillPlayCircleFill style={{transform: 'translateY(2px)'}} />},
  {label: 'How to Play', link: '/how-to-play', icon: <BsBookFill style={{transform: 'translateY(2px)'}} />},
  {label: 'Codex', link: '/codex', icon: <BsBookFill style={{transform: 'translateY(2px)'}} />},
  {label: 'Season', link: '/season', icon: <BsFillTrophyFill style={{transform: 'translateY(2px)'}} />, requireLogin: true},
  {label: 'Races', link: '/races', icon: <BsCarFrontFill style={{transform: 'translateY(2px)'}} />},
  {label: 'Garage', link: '/garage', icon: <BsArchiveFill style={{transform: 'translateY(2px)'}} />, developer: true},
  {label: 'Stats', link: '/stats', icon: <BsFillTrophyFill style={{transform: 'translateY(2px)'}} />, requireLogin: true},
  {label: 'Store', link: '/store', icon: <BsArchiveFill style={{transform: 'translateY(2px)'}} />, developer: true},
  {label: 'Tools', link: '/streamer', icon: <BsTools style={{transform: 'translateY(2px)'}} />, developer: true}
];

interface HeaderContextType {
  toggleHeader: () => void;
}

interface MainPageState {
  login: boolean;
  profile: boolean;
  menu: boolean;
  message: string;
  authenticating: boolean;
  navigate: string;
  returnUrl: string;
  isEditing: boolean;
  newDisplayName: string;
  error: string;
  auth0LoginCompleted: boolean;
  isLoggedIn: boolean;
  playerProfile: any;
  displayName: string;
  authMessage: string;
  headerVisible: boolean;
  showLoginDialog: boolean;
  loginCallback?: () => void;
}
export const HeaderContext = React.createContext<HeaderContextType | undefined>(undefined);


const AUTH0_DOMAIN = 'auth.novarally.io'; 
const AUTH0_CLIENT_ID = 'zNXR7Oxtkp6KQtvpQUZ3uMJZaLiOus28';

function LoginButton() {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    console.log("Initiating Discord login via Auth0");
    sessionStorage.setItem('loginReturnUrl', window.location.pathname);
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}/auth0`,
        connection: 'discord',
        scope: 'openid profile'
      }
    });
  };

  return (
    <button onClick={handleLogin} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <BsDiscord style={{ marginRight: '8px', fontSize: '1.2em' }} />
      Login with Discord
    </button>
  );
}

function GoogleLoginButton() {
  const { loginWithRedirect } = useAuth0();

  const handleGoogleLogin = () => {
    console.log("Initiating Google login via Auth0");
    sessionStorage.setItem('loginReturnUrl', window.location.pathname);
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}/auth0`,
        connection: 'google-oauth2',
        scope: 'openid profile'
      }
    });
  };

  return (
    <button onClick={handleGoogleLogin} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <BsGoogle style={{ marginRight: '8px', fontSize: '1.2em' }} />
      Login with Google
    </button>
  );
}

function TwitchLoginButton() {
  const { loginWithRedirect } = useAuth0();

  const handleTwitchLogin = () => {
    console.log("Initiating Twitch login via Auth0");
    sessionStorage.setItem('loginReturnUrl', window.location.pathname);
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}/auth0`,
        connection: 'twitch',
        scope: 'openid profile'
      }
    });
  };

  return (
    <button onClick={handleTwitchLogin} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <BsTwitch style={{ marginRight: '8px', fontSize: '1.2em' }} />
      Login with Twitch
    </button>
  );
}

function TwitterLoginButton() {
  const { loginWithRedirect } = useAuth0();

  const handleTwitterLogin = () => {
    console.log("Initiating Twitter login via Auth0");
    sessionStorage.setItem('loginReturnUrl', window.location.pathname);
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}/auth0`,
        connection: 'twitter',
        scope: 'openid profile'
      }
    });
  };

  return (
    <button onClick={handleTwitterLogin} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <BsTwitter style={{ marginRight: '8px', fontSize: '1.2em' }} />
      Login with Twitter
    </button>
  );
}

function TikTokLoginButton() {
  const { loginWithRedirect } = useAuth0();

  const handleTikTokLogin = () => {
    console.log("Initiating TikTok login via Auth0");
    sessionStorage.setItem('loginReturnUrl', window.location.pathname);
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}/auth0`,
        connection: 'tiktok',
        scope: 'user.info.basic',
        response_type: 'code'
      }
    });
  };

  return (
    <button onClick={handleTikTokLogin} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <BsTiktok style={{ marginRight: '8px', fontSize: '1.2em' }} />
      Login with TikTok
    </button>
  );
}

function Auth0Integration() {
  const { isLoading, isAuthenticated, getIdTokenClaims, user, loginWithRedirect } = useAuth0();
  const [loginCompleted, setLoginCompleted] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log("Auth0Integration: isLoading:", isLoading, "isAuthenticated:", isAuthenticated);

    async function handleAuth() {
      if (!isLoading) {
        if (isAuthenticated && !loginCompleted) {
          window.dispatchEvent(new CustomEvent('set-auth-message', { detail: 'Completing login, please wait...' }));
          // Check if there's a valid PlayFab session
          if (PlayFab.hasValidSession()) {
            console.log("Auth0Integration: Valid PlayFab session found, running Account methods");
            
            try {
              await Account.finishLogin();
              await Account.isDeveloperLoggedIn();
              console.log("Auth0Integration: Account methods completed");
            } catch (error) {
              console.error("Auth0Integration: Error in Account methods", error);
            }

            setLoginCompleted(true);
            window.dispatchEvent(new Event('auth0-login-completed'));
            window.dispatchEvent(new CustomEvent('set-auth-message', { detail: '' }));
            return;
          }

          console.log("Auth0Integration: Attempting to get ID token claims");
          try {
            const claims = await getIdTokenClaims();
            if (claims && claims.__raw) {
              console.log("Auth0Integration: Got ID token, calling continueLoginWithAuth0");
              const result = await Account.continueLoginWithAuth0(claims.__raw, user);
              if (result.success) {
                console.log("Auth0Integration: Login successful, updating state");
                setLoginCompleted(true);
                // Force a re-render of the parent component
                window.dispatchEvent(new Event('auth0-login-completed'));
                window.dispatchEvent(new CustomEvent('set-auth-message', { detail: '' }));
              } else {
                console.error("Auth0Integration: Login failed", result.error);
              }
            }
          } catch (error) {
            console.error("Auth0Integration: Error getting ID token claims", error);
          }
        } else if (!isAuthenticated && PlayFab.hasValidSession()) {
          window.dispatchEvent(new CustomEvent('set-auth-message', { detail: 'Re-authenticating, please wait...' }));
          await loginWithRedirect({
            appState: { returnTo: window.location.pathname }
          });
        }
      }
    }

    handleAuth();
  }, [isLoading, isAuthenticated, getIdTokenClaims, user, loginCompleted, loginWithRedirect]);

  useEffect(() => {
    if (location.pathname === '/auth0') {
      window.dispatchEvent(new CustomEvent('set-auth-message', { detail: 'Processing login, please wait...' }));
    }
  }, [location]);

  return null;
}

function LogoutButton({ onLogout }: { onLogout: () => void }) {
  const { logout } = useAuth0();
  
  const handleLogout = async () => {
    // Clear Auth0 session
    await logout({ logoutParams: { returnTo: window.location.origin + '/logout' } });
    // Clear account state and local storage
    Account.logout();
    // Disconnect from network
    Network.disconnect();
    // Call the parent's onLogout function
    onLogout();
  };

  return <button onClick={handleLogout}>Logout</button>;
}

// Define the custom event type
interface SetAuthMessageEvent extends CustomEvent {
  detail: string;
}

class MainPage extends React.Component<{}, MainPageState> {
  static contextType = HeaderContext;
  constructor(props: {}) {
    super(props);
    this.state = {
      login: false,
      profile: false,
      menu: false,
      message: '',
      authenticating: true,
      navigate: '',
      returnUrl: '/',
      isEditing: false,
      newDisplayName: Account.getUserName(),
      error: '',
      auth0LoginCompleted: false,
      isLoggedIn: false,
      playerProfile: null,
      displayName: '',
      authMessage: '',
      headerVisible: true,
      showLoginDialog: false,
      loginCallback: undefined
    }
  }

  componentDidMount(): void {
    window.addEventListener('auth0-login-completed', this.handleAuth0LoginCompleted);
    window.addEventListener('playfab-session-check', this.checkPlayFabSession);
    window.addEventListener('set-auth-message', this.handleSetAuthMessage as EventListener);
    this.initializeUser();

    // Add event listener for login popup request
    window.addEventListener('showLogin', ((event: CustomEvent) => {
      // Don't show login dialog if we're already in the auth process
      if (this.state.authenticating) {
        return;
      }

      // Only set returnUrl if it hasn't been set already
      if (!sessionStorage.getItem('loginReturnUrl')) {
        sessionStorage.setItem('loginReturnUrl', window.location.pathname + window.location.search);
      }
      
      this.setState({ 
        login: true,
        loginCallback: event.detail?.callback 
      });
    }) as EventListener);
  }

  componentWillUnmount() {
    window.removeEventListener('auth0-login-completed', this.handleAuth0LoginCompleted);
    window.removeEventListener('playfab-session-check', this.checkPlayFabSession);
    window.removeEventListener('set-auth-message', this.handleSetAuthMessage as EventListener);
  }

  checkPlayFabSession = () => {
    if (PlayFab.hasValidSession() && !this.state.isLoggedIn) {
      console.log("MainPage: Valid PlayFab session found, but not logged in. Triggering re-authentication.");
      window.dispatchEvent(new Event('auth0-login-completed'));
    }
  }

  initializeUser = async () => {
    this.setState({ authenticating: true, message: 'Initializing...' });

    try {
      if (Account.isLoggedIn() || PlayFab.hasValidSession()) {
        this.setState({ message: 'Loading profile...' });
        await this.loadPlayerProfile();
        this.setState({ isLoggedIn: true });
      } else if (Account.hasAutoLogin()) {
        this.setState({ message: 'Logging in, please wait...' });

      }
    } catch (error) {
      console.error('Initialization failed:', error);
    } finally {
      this.setState({ authenticating: false, message: '' });
    }

    // Add this at the end of the method
    setTimeout(() => {
      window.dispatchEvent(new Event('playfab-session-check'));
    }, 1000); // Check after a short delay
  };

  loadPlayerProfile = async () => {
    if (PlayFab.hasValidSession()) {
      try {
        const profile = await PlayFab.getPlayerProfile();
        this.setState({ 
          playerProfile: profile,
          displayName: profile.TitleDisplayName || Account.getUserName(),
          newDisplayName: profile.TitleDisplayName || Account.getUserName()
        });
        console.log("Updated player profile:", profile);
      } catch (error) {
        console.error('Error loading player profile:', error);
      }
    }
  }

  handleAuth0LoginCompleted = () => {
    console.log("MainPage: Auth0 login completed");
    this.setState({ 
      auth0LoginCompleted: true, 
      isLoggedIn: true,
      login: false,
      authenticating: false  // Make sure to reset authenticating
    }, () => {
      if (this.state.loginCallback) {
        this.state.loginCallback();
        this.setState({ loginCallback: undefined });
      }
      
      this.loadPlayerProfile();
    });
  }

  onLogin() {
    // Store current path before showing login dialog
    sessionStorage.setItem('loginReturnUrl', window.location.pathname + window.location.search);
    
    this.setState({
      login: true,
      // Remove returnUrl from state since we're using sessionStorage
      // returnUrl: window.location.pathname + window.location.search
    });
  }

  onLogout = () => {
    Account.logout();
    this.setState({
      message: '',
      auth0LoginCompleted: false,
      profile: false,
      isLoggedIn: false,
      playerProfile: null,
      displayName: '',
      // Reset other relevant state properties
    });
    // Optionally, redirect to home page
    this.setState({navigate: '/'});
  }

  onProfile() {
    this.setState({profile: true});
  }

  onMenu() {
    this.setState({menu: true});
  }

  renderHeaderMenu(name:string, path:string) {
    return (
      <NavLink to={path} className="main-page-header-link">
        {name}
      </NavLink>
    )    
  }

  renderHeader() {
    let links = [];
    for(let i = 0; i < menu.length; i++) {
      if(menu[i].developer && !Account.isDeveloperLoggedIn()) 
        continue;
      if(menu[i].requireLogin && !Account.isLoggedIn())
        continue;

      links.push(
        <NavLink key={i} to={menu[i].link} className="main-page-header-link">
          {menu[i].label}
        </NavLink>
      )
    }

    return (
      <div className="main-page-header">
        <div className="main-page-header-grid">
          <div className="main-page-header-menu" onClick={()=>this.onMenu()}>
            <BsFilterCircleFill />
          </div>

          <div className="main-page-header-logo">
            <img style={{height: '36px', transform: 'translate(-4px, 3px)'}} src="/images/logo.webp"/>
          </div>

          <div className="main-page-header-links">
            {links}
          </div>

          {this.state.authenticating &&
            <div className="main-page-header-authenticating-button">
              <BsBroadcast/>
            </div>
          }
          {!this.state.authenticating && this.state.playerProfile &&
            <div className="main-page-header-portrait" onClick={()=>this.onProfile()}>
              <img style={{width: '100%', borderRadius: '50%'}} src={this.state.playerProfile.AvatarUrl} alt="User Avatar" />
            </div>
          }
          {!this.state.authenticating && !this.state.playerProfile &&
            <div className="main-page-header-login-button" onClick={()=>this.onLogin()}>
              <BsPersonCircle />
            </div>
          }
          {!this.state.authenticating && !this.state.playerProfile &&
            <div className="main-page-header-login" onClick={()=>this.onLogin()}>
              Log In
            </div>
          }
        </div>
      </div>
    )
  }

  renderLoginDialog() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{width: '250px'}}>
          <div>
            <img style={{height: '36px'}} src="/images/logo.webp"/>
          </div>
          <div className="flex-column" style={{marginTop: '10px'}}>
            <LoginButton />
            <GoogleLoginButton />
            <TwitchLoginButton />
            <TwitterLoginButton />
            <TikTokLoginButton />
            <button disabled={true}>
              <BsFacebook style={{transform: 'translateY(3px)'}} />&nbsp;&nbsp;Login with Facebook
            </button>
            <button disabled={true}>
              <BsEnvelope style={{transform: 'translateY(4px)'}} />&nbsp;&nbsp;Login with Email
            </button>
            <button style={{background: '#213e70'}} onClick={() => this.setState({login: false})}>Close</button>
          </div>
        </div>
      </div>
    )
  }

  renderProfileDialog() {
    if (!this.state.playerProfile) return null;

    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{width: '250px'}}>
          <div className="flex-column">
            <img 
              style={{borderRadius: '50%', width: '120px', margin: 'auto'}} 
              src={this.state.playerProfile.AvatarUrl} 
              alt="User Avatar" 
            />
            {this.state.isEditing ? (
              <div>
                <input
                  type="text"
                  value={this.state.newDisplayName}
                  onChange={(e) => this.setState({ newDisplayName: e.target.value })}
                  maxLength={25}
                />
                <button onClick={this.handleSave}>Save</button>
                <button onClick={this.handleCancel}>Cancel</button>
                {this.state.error && <p className="error">{this.state.error}</p>}
              </div>
            ) : (
              <div>
                <div>{this.state.displayName}</div>
                <button onClick={this.handleEdit}>Edit Display Name</button>
              </div>
            )}
            <div className="flex-column" style={{marginTop: '10px'}}>
              <button>Account</button>
              <button>Settings</button>
              <LogoutButton onLogout={this.onLogout} />
              <button style={{background: '#213e70'}} onClick={() => this.setState({profile: false})}>Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleEdit = () => {
    this.setState({ isEditing: true, newDisplayName: this.state.displayName, error: '' });
  }

  handleCancel = () => {
    this.setState({ isEditing: false, newDisplayName: this.state.displayName, error: '' });
  }

  handleSave = async () => {
    if (this.state.newDisplayName.trim() === '') {
      this.setState({ error: 'Display name cannot be empty' });
      return;
    }

    this.setState({ message: 'Updating display name...' });

    try {
      const success = await Account.updateDisplayName(this.state.newDisplayName.trim());
      if (success) {
        this.setState({ isEditing: false, error: '', message: 'Refreshing profile...' });
        
        // Perform another GetPlayerProfile call
        await this.loadPlayerProfile();
        
        this.setState({ message: '' });
      } else {
        this.setState({ error: 'Failed to update display name. Please try again.', message: '' });
      }
    } catch (error) {
      console.error('Error updating display name:', error);
      this.setState({ error: 'An error occurred while updating display name.', message: '' });
    }
  }

  renderMessageDialog() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{maxWidth: '250px', padding: '20px'}}>
          {this.state.message}
        </div>
      </div>
    )
  }

  renderMenuDialog() {
    let buttons = [];
    for(let i = 0; i < menu.length; i++) {
      if(menu[i].developer && !Account.isDeveloperLoggedIn()) 
        continue;
      
      buttons.push(
        <NavLink key={i} to={menu[i].link}>
          <button style={{width: '100%'}} onClick={()=>this.setState({menu: false})}>
            {menu[i].icon}&nbsp;&nbsp;{menu[i].label}
          </button>
        </NavLink>
      )
    }

    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{width: '220px'}}>
          <div>
            <img style={{height: '36px'}} src="/images/logo.webp"/>
          </div>
          <div className="flex-column" style={{marginTop: '10px'}}>
            {buttons}
            <button style={{background: '#213e70'}} onClick={()=>this.setState({menu: false})}>Close</button>
          </div>
        </div>
      </div>
    )
  }

  handleSetAuthMessage = (event: SetAuthMessageEvent) => {
    this.setState({ authMessage: event.detail });
  }

  renderAuthMessageDialog() {
    if (!this.state.authMessage) return null;
    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{maxWidth: '250px', padding: '20px'}}>
          {this.state.authMessage}
        </div>
      </div>
    )
  }

  toggleHeader = () => {
    this.setState(prevState => ({ headerVisible: !prevState.headerVisible }));
  }

  // When login completes successfully
  onLoginSuccess = () => {
    if (this.state.loginCallback) {
      this.state.loginCallback();
    }
    this.setState({ 
      login: false,
      loginCallback: undefined
    });
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />
      
    return (
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/auth0`,
          scope: 'openid profile'
        }}
      >
        <Auth0Integration />
        <HeaderContext.Provider value={{ toggleHeader: this.toggleHeader }}>
        <div>
          {this.state.headerVisible && this.renderHeader()}
          {/* <button 
            onClick={this.toggleHeader}
            style={{
              position: 'fixed',
              top: '10px',
              right: '10px',
              zIndex: 1000,
              padding: '5px 10px',
              background: '#213e70',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            {this.state.headerVisible ? 'Hide Header' : 'Show Header'}
          </button> */}
          {(!this.state.authenticating && (this.state.auth0LoginCompleted || !Account.isLoggedIn())) &&
            <Outlet />
          }
          {this.state.login && this.renderLoginDialog()}
          {this.state.profile && this.renderProfileDialog()}
          {this.state.menu && this.renderMenuDialog()}
          {this.state.message != '' && this.renderMessageDialog()}
          {this.renderAuthMessageDialog()}
          {this.state.showLoginDialog && (
            <div className="dialog-cover">
              <div className="dialog-panel" style={{width: '250px'}}>
                <div>Please login to play</div>
                <div>
                  <button 
                    style={{marginTop: '25px'}} 
                    onClick={() => {
                      // Trigger MainPage login popup
                      window.dispatchEvent(new CustomEvent('showLogin', {
                        detail: { callback: this.onLoginSuccess }
                      }));
                    }}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        </HeaderContext.Provider>
      </Auth0Provider>
    );
  }
}

export default MainPage;